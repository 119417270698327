import React from "react";
import Main from "./Main";
import Tasks from "App/Tasks.js";
import {addAsyncReducers} from "./Reducer.js";
import {Router} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {HOPSApplication} from "@hps/hops-react";
import {History, UnhandledErrorView} from "@hps/hops-react";

/**
 * App entrypoint
 *
 * Renders the root-level application component.
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class App extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * Inner <Sentry.ErrorBoundary /> is required so that the 
	 * HOPS Application component is still initialised (for Theme, etc.)
	 *
	 * @return {ReactNode}
	 */
	render() {

		// Add our local reducers to the Redux store
		addAsyncReducers();

		return (
			<Sentry.ErrorBoundary fallback={<UnhandledErrorView {...this.props} />}>
				<HOPSApplication tasks={Tasks}>
					<Router history={History}>
						<Sentry.ErrorBoundary fallback={<UnhandledErrorView {...this.props} />}>
							<Main />
						</Sentry.ErrorBoundary>
					</Router>
				</HOPSApplication>
			</Sentry.ErrorBoundary>
		);
	}

}

export default App;
