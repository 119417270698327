import React from "react";
import {dNewOrderHighlightTime, dOldOrderHighlightTime} from "Dispatchers/dAppSettings";
import {DeviceSettingsView, Flex, TextField, withRegistration} from "@hps/hops-react";
import withAppSettings from "Hoc/withAppSettings";

/**
 * Device settings view
 * 
 * @package HOPS
 * @subpackage Settings
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class SettingsView extends React.PureComponent {


	/**
	 * New Order highlight time changed.
	 *
	 * @param {Integer} NewOrderHighlightTime
	 * @return {void}
	 */
	static handleChangeNewOrderHighlightTime = NewOrderHighlightTime => {
		dNewOrderHighlightTime(parseInt(NewOrderHighlightTime) || 0);
	};


	/**
	 * New Order highlight time changed.
	 *
	 * @param {Integer} OldOrderHighlightTime
	 * @return {void}
	 */
	static handleChangeOldOrderHighlightTime = OldOrderHighlightTime => {
		dOldOrderHighlightTime(parseInt(OldOrderHighlightTime) || 0);
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<DeviceSettingsView localSettings={this.data} />
		);
	}


	/**
	 * Table data definition.
	 * 
	 * @return {Array}
	 */
	get data() {
		return [
			{
				label: "New Order Highlight Time",
				content: (
					<Flex
						alignItems="center"
						columnar={true}
						gap={2}>
						<Flex alignItems="flex-start">
							<TextField
								inputCharPattern="^[0-9]+$"
								label="Seconds"
								inputProps={this.constructor.numericFieldProps}
								onChange={this.constructor.handleChangeNewOrderHighlightTime}
								value={(this.props.AppSettings?.NewOrderHighlightTime?.toString() || "")} />
						</Flex>
					</Flex>
				)
			},
			{
				label: "Old Order Highlight Time",
				content: (
					<Flex
						alignItems="center"
						columnar={true}
						gap={2}>
						<Flex alignItems="flex-start">
							<TextField
								inputCharPattern="^[0-9]+$"
								label="Seconds"
								inputProps={this.constructor.numericFieldProps}
								onChange={this.constructor.handleChangeOldOrderHighlightTime}
								value={(this.props.AppSettings?.OldOrderHighlightTime?.toString() || "")} />
						</Flex>
					</Flex>
				)
			}
		];
	}


	/**
	 * Numeric field setting `inputProps`
	 * 
	 * @type {Object}
	 */
	static numericFieldProps = {inputMode: "numeric"};

}

export default withRegistration(withAppSettings(SettingsView));
