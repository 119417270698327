import State from "./State";
import {Store} from "@hps/hops-react";

/**
 * Async Reducers which are specific to this hosted app.
 * They are combined with the static reducer(s) in hops-react.
 */
export const addAsyncReducers = () => {

	// Updated list of Orders (Persisted)
	Store.addAsyncReducer("OrderFulfilment", (state = State.Orders, action = {}) => {
		switch (action.type) {
			case "orders/update":
				return {...state, Orders: action.Orders?.Orders};
			case "orders/pending":
				return {...state, Pending: action.Pending};
			case "orders/syncing":
				return {...state, Syncing: action.Syncing};
			default:
				return state;
		}
	}, true);

	// UI State changes (Persisted)
	Store.addAsyncReducer("Ui", (state = State.Ui, action = {}) => {

		switch (action.type) {
			case "ui/orderFulfilmentTab":
				return {...state, OrderFulfilmentTab: action.OrderFulfilmentTab};
			default:
				return state;
		}
	}, true);

	// App Setting changes (Persisted)
	Store.addAsyncReducer("AppSettings", (state = State.AppSettings, action = {}) => {

		switch (action.type) {
			case "settings/update":
				return action.AppSettings;
			case "settings/newOrderHighlightTime":
				return {...state, NewOrderHighlightTime: action.NewOrderHighlightTime};
			case "settings/oldOrderHighlightTime":
				return {...state, OldOrderHighlightTime: action.OldOrderHighlightTime};
			default:
				return state;
		}
	}, true);

};

export default addAsyncReducers;
