import dOrders from "Dispatchers/dOrders.js";
import {Bghost, SnackbarService} from "@hps/hops-react";
import {FulfilmentService} from "@hps/hops-sdk-js";
import {dOrdersPending, dOrdersSyncing} from "Dispatchers/dOrders";

const GetOrders = async () => {

	dOrdersSyncing(true);

	try {

		const orders = await Bghost.exec(FulfilmentService.getOrders());

		// Update state objects
		dOrders(orders);
		dOrdersPending(false);

	}
	catch (e) {

		// If something went wrong, show a persistent warning snackbar.
		SnackbarService.snack(
			`Update Error: ${e}`,
			"warning",
			{
				persist: true,
				withCloseButton: true
			}
		);

		// Flag state that we're waiting for valid data
		dOrdersPending(true);

	}

	dOrdersSyncing(false);

};


export default GetOrders;
