import React from "react";
import Router from "./Router.js";
import UiBar from "Ui/UiBar.js";
import {Flex, UnhandledErrorView, withRegistration} from "@hps/hops-react";
import * as Sentry from "@sentry/react";

/**
 * Main application component
 *
 * Handles application-level activities and renders the app UI and router.
 *
 * @package HOPS
 * @subpackage App
 * @author Carl Booth
 * @copyright Heritage Operations Processing Limited
 */
class Main extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return <>
			{(this.props.registered && <UiBar />)}
			<Flex px={1} py={1}>
				<Sentry.ErrorBoundary fallback={UnhandledErrorView}>
					<Router />
				</Sentry.ErrorBoundary>
			</Flex>
		</>;
	}

}

export default withRegistration(Main);
