/**
 * Represents the default state passed in to the application host's reducer (hops-react).
 */
const State = {

	/**
	 * Orders data.
	 *
	 * @type {Object}
	 */
	Orders: {
		Orders: [],
		Pending: false,
		Syncing: false
	},

	/**
	 * UI state data.
	 *
	 * @type {Object}
	 */
	Ui: {
		/**
		 * Currently active UI tab in Order Fulfilment
		 * 
		 * @type {int}
		 */
		OrderFulfilmentTab: 0
	},

	/**
	 * Hosted app-specific default settings
	 *
	 * @type {Object}
	 */
	AppSettings: {

		/**
		 * How long in seconds to highlight new orders (1 minute)
		 * 
		 * @type {int}
		 */
		NewOrderHighlightTime: 60,


		/**
		 * Highlight old orders after this long in seconds (15 minutes)
		 * 
		 * @type {int}
		 */
		OldOrderHighlightTime: 900

	}


};

export default State;
