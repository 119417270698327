import React from "react";
import {Button, Divider, Flex, Hidden, Paper, String} from "@hps/hops-react";
import CheckIcon from "@mui/icons-material/Check";
import RestoreIcon from "@mui/icons-material/Restore";
import scss from "./OrderCard.module.scss";
import moment from "moment";
import pluralize from "pluralize";
import withAppSettings from "Hoc/withAppSettings";
import withOrderFulfilment from "Hoc/withOrderFulfilment.js";

/**
 * Order Card
 * 
 * This may be displayed on a kitchen counter / warehouse wall some distance from the end user.
 * Use larger font sizes where appropriate and keep the UI simple/clear with minimal interaction required.
 *  
 * @package HOPS
 * @subpackage OrdersDisplay
 * @author Carl Booth
 * @copyright Heritage Operations Processing Limited
 */


const OrderCard = props => {

	const currentDateTime = new moment();
	const receivedDateTime = new moment(props.order?.Timestamp);
	const fulfilledDateTime = new moment(props.order?.FulfilledTimestamp);

	const isNewOrder = props.order?.Timestamp &&
						(currentDateTime - receivedDateTime) < (props.AppSettings.NewOrderHighlightTime * 1000);

	const isOldOrder = props.order?.Timestamp &&
						(currentDateTime - receivedDateTime) > (props.AppSettings.OldOrderHighlightTime * 1000);

	const isFulfilledOrder = props.order?.FulfilledTimestamp;

	const itemsRequiringFulfilment = props.order?.Items?.filter(i => i.RailwayProduct?.RequiresFulfilment === true);
	const itemsNotRequiringFulfilment = props.order?.Items?.filter(i => i.RailwayProduct === null || i.RailwayProduct?.RequiresFulfilment === false);

	const handleOrderComplete = () => {
		props.onComplete(props.order?.Id);
	};

	const handleOrderRestore = () => {
		props.onRestore(props.order?.Id);
	};

	return (
		<Paper classNamePaper={`${scss.paper} ${(!isFulfilledOrder && isNewOrder && scss.NewOrder)} ${(!isFulfilledOrder && isOldOrder && scss.OldOrder)}`}>

			<Flex justifyContent="space-between" columnar={true}>
				<String
					bold={!isFulfilledOrder && isOldOrder}
					className={scss.textCenter}
					color={!isFulfilledOrder && isOldOrder ? "error" : "textPrimary"}
					gap={0.25}
					str={(isFulfilledOrder ? "Fulfilled" : receivedDateTime.fromNow())}
					variant="h6" />
				<String
					className={scss.textCenter}
					color="textSecondary"
					gap={0.25}
					str={(isFulfilledOrder ? fulfilledDateTime.format("HH:mm") : receivedDateTime.format("HH:mm"))}
					variant="h5" />
			</Flex>

			<Divider/>

			<String
				color="textSecondary"
				gap={0.25}
				str={`Order L${props.order?.Id} from ${props.order?.PosDevice?.Name}`}
				variant="subtitle1" />

			{itemsRequiringFulfilment.map((item, key) => {

				// Only show the qty (eg '2x') if it's > 1.
				const itemLabel = item.Qty > 1 ?
					`${item.Qty}x ${item.Description}` :
					item.Description;

				return <String
					bold={true}
					inline={true}
					key={key}
					str={itemLabel}
					variant="h6" />;
			})}

			<Hidden hidden={!itemsNotRequiringFulfilment.length}>
				<String
					color="textSecondary"
					inline={true}
					str={`(with ${itemsNotRequiringFulfilment.length} other ${pluralize("item", itemsNotRequiringFulfilment.length)})`} />
			</Hidden>

			<Divider/>


			{!isFulfilledOrder ?
				<Button
					className={scss.DoneButton}
					disabled={props.OrderFulfilment.Syncing}
					label="Complete"
					onClick={handleOrderComplete}
					startIcon={CheckIcon}
					size="large"
					variant="contained" /> :
				<Button
					className={scss.DoneButton}
					disabled={props.OrderFulfilment.Syncing}
					color="error"
					label="Restore"
					onClick={handleOrderRestore}
					startIcon={RestoreIcon}
					size="large" />
			}

		</Paper>);

};

export default withOrderFulfilment(withAppSettings(OrderCard));
