import React from "react";
import * as Sentry from "@sentry/react";
import scss from "./OrdersDisplayView.module.scss";
import OrdersDisplayList from "./OrdersDisplayList";
import dOrderFulfilmentTab from "Dispatchers/dOrderFulfilmentTab";
import {TabPanel, UnhandledErrorView} from "@hps/hops-react";

import withOrderFulfilment from "Hoc/withOrderFulfilment";
import withUi from "Hoc/withUi";


/**
 * Orders display view
 * 
 * @package HOPS
 *  @subpackage OrdersDisplay
 * @author Carl Booth
 * @copyright Heritage Operations Processing Limited
 */


const OrdersDisplayView = props => {

	const inProgressOrders = props.OrderFulfilment?.Orders.filter(io => io.FulfilledTimestamp === null);
	const completedOrders = props.OrderFulfilment?.Orders.filter(co => !(co.FulfilledTimestamp === null));

	const tabs = [
		{
			label: "In Progress",
			hidden: false,
			component: OrdersDisplayList,
			componentProps: {orders: inProgressOrders}
		},
		{
			label: "Completed",
			hidden: false,
			component: OrdersDisplayList,
			componentProps: {orders: completedOrders}
		}
	].filter(t => !t.hidden);


	return (
		<Sentry.ErrorBoundary fallback={UnhandledErrorView}>
			<TabPanel
				FlexProps={{className: scss.root}}
				gap={1}
				// noTabsState={InventoryNoFeaturesError}
				onChange={dOrderFulfilmentTab}
				tabs={tabs}
				scrollButtons="auto"
				value={props.Ui?.OrderFulfilmentTab || 0}
				variant="scrollable" />
		</Sentry.ErrorBoundary>
	);

};

export default withOrderFulfilment(withUi(OrdersDisplayView));
