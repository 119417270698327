import React from "react";
import Routes from "./Routes.js";
import {withRegistration} from "@hps/hops-react";
import {Route, Switch} from "react-router-dom";

/**
 * Router
 *
 * The application-level router that renders the main routes.
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Router extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Switch>
				{
					this.routes.map((route, key) => (
						<Route
							component={route.component}
							exact={!route.inexact}
							key={key}
							path={route.uri} />
					))
				}
			</Switch>
		);
	}


	/**
	 * Get the available routes to render.
	 * 
	 * @return {Array}
	 */
	get routes() {
		return Routes.filter(route => {
			if (!route.allowWithoutDeviceRegistration && !this.props.registered) return false;
			else return true;
		});
	}

}

export default withRegistration(Router);
