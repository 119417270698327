import React from "react";
import OrderFulfilmentIcon from "@mui/icons-material/Inbox";
import LocationIcon from "@mui/icons-material/Room";
import SyncIconProgress from "@mui/icons-material/Autorenew";
import scss from "./UiBar.module.scss";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Api} from "@hps/hops-sdk-js";
import {Animate, AppBar, ButtonBase, Flex, Navigator, Link, ListItem, Tasker, withRegistration} from "@hps/hops-react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";


/**
 * App bar component
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBar extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {

		const centreMenu = {
			onChange: this.handleToggleButton,
			value: this.props.location.pathname,
			items: [
				{
					label: "Order Fulfilment",
					icon: <OrderFulfilmentIcon />,
					value: "/"
				}
			]
		};


		const rightMenu = {
			onChange: this.handleToggleButton,
			value: this.props.location.pathname,
			items: [
				{
					label: null,
					icon: this.syncIcon,
					value: "sync"
				}
			]
		};

		return (
			<AppBar
				centreMenu={centreMenu}
				rightMenu={rightMenu}>
				<Flex
					columnar={true}
					justifyContent="center">
					<ButtonBase
						className={scss.orgButton}
						color="inherit"
						focusRipple={true}
						onClick={this.constructor.handleHome}
						size="large">
						<img
							alt=""
							className={scss.orgLogo}
							src={`${Api.baseURL}/uploads/railway_logos/${this.props.Registration?.Org?.Logo}`} />
					</ButtonBase>
				</Flex>
				<Flex
					columnar={true}
					gap={2}>
					<Flex gap={0.25}>
						<ListItem icon={LocationIcon}>
							<Link color="inherit" label={this.props.Registration?.Device?.Name} StringProps={this.constructor.stringProps} uri="/device" />
						</ListItem>
					</Flex>
				</Flex>
			</AppBar>
		);
	}


	/**
	 * Get the icon component to render.
	 * 
	 * @return {ReactNode}
	 */
	get syncIcon() {
		if (this.props.Status?.Syncing) return <Animate animation="spin" component={SyncIconProgress} />;
		else if (this.props.OrderFulfilment?.Pending) return <SyncProblemIcon htmlColor="#ffb700" />;
		else return <CheckCircleIcon />;
	}


	/**
	 * Props to apply to `String`-like components.
	 * 
	 * @type {Object}
	 */
	static stringProps = {lineClamp: 1, noFlex: true};


	/**
	 * Toggle Button handler.
	 *
	 * @type {Function}
	 */
	// eslint-disable-next-line class-methods-use-this
	handleToggleButton = (e, value) => {
		switch (value) {
			case "sync":
				Tasker.runAll();
				return;
			default:
				Navigator.navigate((value || window.location.pathname));
		}
	};

}

export default connect(({OrderFulfilment, Status}) => ({OrderFulfilment, Status}))(withRegistration(withRouter(UiBar)));
