import React from "react";
import scss from "./OrdersDisplayList.module.scss";
import {Grid, EmptyStateGate} from "@hps/hops-react";
import {FulfilmentService} from "@hps/hops-sdk-js";
import OrderCard from "./OrderCard";
import GetOrders from "Tasks/GetOrders";
import {dOrdersSyncing} from "Dispatchers/dOrders";

/**
 * In-Progress Orders view
 *  
 * @package HOPS
 * @subpackage OrdersDisplay
 * @author Carl Booth
 * @copyright Heritage Operations Processing Limited
 */


const OrdersDisplayList = props => {

	const handleOrderMarkComplete = async order => {

		// GetOrders does this, but also do it now for snappier UI response while waiting for updateOrder
		dOrdersSyncing(true);

		await FulfilmentService.updateOrder(order, true).then(() => GetOrders());
	};

	const handleOrderRestore = async order => {

		// GetOrders does this, but also do it now for snappier UI response while waiting for updateOrder
		dOrdersSyncing(true);

		await FulfilmentService.updateOrder(order, false).then(() => GetOrders());
	};

	return (
		<div className={scss.root}>
			<EmptyStateGate isEmpty={!props.orders}>
				<Grid container >
					{props.orders?.map(
						(order, key) => (
							<Grid
								className={scss.GridItem}
								item
								key={key}
								xs={12}
								sm={6}
								md={4}
								lg={3}
								xl={2}>
								<OrderCard
									onComplete={handleOrderMarkComplete}
									onRestore={handleOrderRestore}
									order={order} />
							</Grid>
						)
					)}
				</Grid>
			</EmptyStateGate>
		</div>
	);

};

export default OrdersDisplayList;
