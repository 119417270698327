import SettingsView from "Settings/SettingsView.js";
import {InvalidUriView} from "@hps/hops-react";
import OrdersDisplayView from "OrdersDisplay/OrdersDisplayView";
import RegistrationView from "Registration/RegistrationView.js";

const Routes = [
	{
		uri: "/device",
		component: SettingsView,
		allowWithoutAuth: true
	},
	{
		uri: "/",
		component: OrdersDisplayView
	},
	{
		uri: "/",
		component: RegistrationView,
		allowWithoutDeviceRegistration: true
	},
	{
		uri: "/",
		component: InvalidUriView,
		inexact: true,
		allowWithoutDeviceRegistration: true
	}
];

export default Routes;
