import React from "react";
import {DeviceRegistrationView, Flex, String} from "@hps/hops-react";
import {DeviceTypes} from "@hps/hops-sdk-js";
import scss from "./RegistrationView.module.scss";
/**
 * Registration view
 *
 * Adds a header to the hops-react <DeviceRegistrationView />
 * 
 * @package HOPS
 * @subpackage Registration
 * @copyright Heritage Operations Processing Limited
 */
class RegistrationView extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<DeviceRegistrationView deviceType={DeviceTypes.Fulfilment}>
				<Flex alignItems="center" className={scss.header} gap={2}>
					<img
						alt="HOPS"
						className={scss.hopsLogoImg}
						src="/icon.png" />
					<String
						bold={true}
						centre={true}
						component="h1"
						str="HOPS Fulfilment"
						variant="h5" />
				</Flex>
			</DeviceRegistrationView>
		);
	}

}

export default RegistrationView;
