import GetOrders from "Tasks/GetOrders";

const Tasks = [
	{
		id: "GetOrders",
		task: GetOrders,
		frequency: 30,
		requireOnline: true,
		requireRegistration: true
	}
];

export default Tasks;
